<template>
	<div class="bg-F7F7F7 paddt20 mh100">
		<div class="mart10 w1200 bg-white margin-center paddlr20">
			<div v-html="pagedetails.content" class="html-box ql-editor content-detail">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		useRoute
	} from 'vue-router';
	export default {
		name: 'SchoolList',
		data() {
			return {
			
				pagedetails:''
			}
		},
		
		created() {
			let data = useRoute().query;
			console.log(data.id)
			this.$req({ 
			method: 'get',
			url: '/api/article/detail',
			data: {
			id: data.id,
			},
			success: res => {	
				console.log(res,'+++++++++++')
				this.pagedetails  = res
			},
			fail: error => {}
			});
		},
		methods: {
		}
	}
</script>

<style>
	
</style>
